import React, { Component } from 'react';
import Slider from 'react-slick';
import LayoutWrapper from 'src/layouts/LayoutWrapper'
import './aboutPage.scss'
import Hero from 'components/Hero/Hero'
const linkedinIcon = require(`content/linkedin-icon.png`);
const mailIcon = require(`content/mail-icon.png`);
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class AboutPage extends Component {
	isMobile = false

	state = {
		memberVisible: null
	}

	openProfile = (index) => {

		this.state.memberVisible == null ?
			this.setState({
				memberVisible: index
			})

			:
			this.setState({
				memberVisible: null
			})

	}

	render() {
		if (typeof window != 'undefined') {
			this.isMobile = window.outerWidth <= 800 ? true : false
		}

		const {
			memberVisible
		} = this.state

		const {
			data
		} = this.props

		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 4,
			slidesToScroll: 4,
			autoplay: true,
			autoplaySpeed: 3000
		};

		const thisPageData = data.contentfulAboutPage

		const checkMarkIcon = require('content/checkmark.png');

		const teamMembers = thisPageData.teamMembers

		return (
			<LayoutWrapper>
				<div className="about-page-container">
					<Hero
						backgroundImage={thisPageData.heroBackgroundImage ? thisPageData.heroBackgroundImage.file.url : null}
						title={thisPageData.heroTitle ? thisPageData.heroTitle.heroTitle : null}
						copy={thisPageData.heroCopy ? thisPageData.heroCopy.heroCopy : null}
					/>
					<div className="highlights-container">
						<div className="container">
							<ul>
								{thisPageData.highlights && thisPageData.highlights.map((highlight, index) => {
									return (
										<li key={index}>
											<img src={highlight.image.file.url} />
											<h2>
												{highlight.title}
											</h2>
											<p>
												{highlight.copy.copy}
											</p>
										</li>
									)
								})}
							</ul>
						</div>
					</div>
					<div className="timeline-container">
						<div className="container">
							<div className="line"></div>
							<ul className="hide-scrollbar">
								{thisPageData.timelineEntries && thisPageData.timelineEntries.map((timelineEntry, index) => {
									return (
										<li 
											key={index} 
											className="timeline-column"
											style={{
												width: (Math.floor(100 / thisPageData.timelineEntries.length))+'%'
											}}
										>
											<div className="content">
												<div>
													<img src={checkMarkIcon} />
													<h4>
														{timelineEntry.title}
													</h4>
													<p>
														{timelineEntry.copy.copy}
													</p>
												</div>
											</div>
											<p className="year">
												{timelineEntry.subtitle}
											</p>
										</li>
									)
								})}
							</ul>
						</div>
					</div>
					<div className="partners-container">
						<div className="container">
							<h2>
								Partners
              </h2>

							<Slider {...settings}>
								{thisPageData.partners && thisPageData.partners.map((partner, partnerIndex) => (
									<div key={partnerIndex}>
										<img src={partner.image.file.url} />
									</div>
								)
								)}
							</Slider>
						</div>
					</div>
					<div className="press-container">
						<div className="container">
							<h2>
								Recent press
              </h2>
							<ul>
								{thisPageData.press && thisPageData.press.map((press, pressIndex) => {
									return (
										<li key={pressIndex}>
											<a href={press.websiteUrl} target="_blank">
												<img src={press.image.file.url} />
												<div>
													<p>
														{press.subtitle}
													</p>
													<h2>
														{press.title}
													</h2>
												</div>
											</a>
										</li>
									)
								})}
							</ul>
						</div>
					</div>
					<div className="team-members-container">
						<div className="container">
							<h2>
								Our team
              				</h2>
							<div className="main">
								<ul className="team-members">
									{teamMembers && teamMembers.map((teamMember, memberIndex) => {

										let memberClass

										if (memberVisible != null) {
											if (memberIndex == memberVisible) {
												memberClass = 'active'
											} else {
												memberClass = 'hidden'
											}
										} else {
											memberClass = ''
										}

										let thisPersonModulo = ((memberIndex % 4) + 1)

										if (thisPersonModulo == 0) {
											thisPersonModulo = 4
										}

										let memberStyle

										if (!this.isMobile) {

											if (thisPersonModulo < 3) {

												let leftVal = '100%'

												memberStyle = {
													left: '100%',
													transform: 'translateX(8.2%)'
												}

											} else {

												memberStyle = {
													right: '100%',
													transform: 'translateX(-8.2%)',
													textAlign: 'right'
												}

											}
										}

										return (
											<li className={memberClass} key={memberIndex}>
												<div>
													<div className="person-profile" onClick={_ => { this.openProfile(memberIndex) }}>
														<img src={teamMember.image.file.url} />
														<div>
															<h2>
																{teamMember.title}
															</h2>
															<p>
																{teamMember.subtitle}
															</p>
															<span className="toggle-profile">
																<i className="fa fa-plus"></i>
																<i className="fa fa-minus"></i>
															</span>
														</div>
													</div>
													<div className="person-content" style={memberStyle}>
														<p>
															{teamMember.copy.copy}
														</p>
														<ul className="icon-row">
															<li>
																<a className="icon" href={teamMember.websiteUrl}>
																	<img src={linkedinIcon} />
																</a>
															</li>
															<li>
																<a className="icon" href={teamMember.emailAddress}>
																	<img src={mailIcon} />
																</a>
															</li>
														</ul>
													</div>
												</div>
											</li>
										)
									})}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</LayoutWrapper>
		)
	}
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query aboutPageQuery($id: String!) {
    contentfulAboutPage(id: { eq: $id }) {
      heroBackgroundImage {
        file {
          url
        }
      }
      heroTitle {
				heroTitle
			}
			heroCopy {
				heroCopy
			}
      highlights {
        image {
          file {
            url
          }
        }
        title
        copy {
          copy
        }
      }
      timelineEntries {
        title
        subtitle
        copy {
          copy
        }
      }
      partners {
        image {
          file {
            url
          }
        }
      }
      press {
        image {
          file {
            url
          }
        }
        title 
				subtitle
				websiteUrl
      }
      teamMembers {
        image {
          file {
            url
          }
        }
        title 
        subtitle
        copy {
          copy
        }
        websiteUrl
        emailAddress
      }
    }
  }
`;